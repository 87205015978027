import React, {useEffect, useState, useReducer } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/FileProfile/Body/Body';
import { handleShareClick, setInitialState, getUserRoles, getDispFields } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import { checkIsFollowed, checkProfileEditable, checkTermLinkable } from '../../permissionChecker';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  normalText: {
    color: theme.palette.primaryText.main,
  }
});

const initialState = {
  tabState:0,
  noteTabState:0,
  noteDetailMap:{},
  usagePeriod:3,
  usageType:'direct',
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],
    downstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],

    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_link_collection_modal_open':
      return {
        ...state,
        linkCollectionModalOpen:action.linkCollectionModalOpen
      }
    case 'set_collection_instances':
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData: action.issueData,
        issueError: action.issueError,
        issueLoading: action.issueLoading
      }
    case 'set_dqModal_open':
      return {
        ...state,
        dqModalOpen: action.dqModalOpen
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const FileProfile = props => {
  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const roles = getUserRoles(sessionData.user_role);

  const getTabOptions = data => {
    // return ['DETAILS','KNOWLEDGE',roles.every(el=>el==='90')||data.usage_txt==='UNKNOWN'?undefined:'USAGE','LINEAGE','ISSUES'].filter(el=>el!==undefined);
    return ['DETAILS','KNOWLEDGE',roles.every(el=>el==='90')?undefined:'USAGE','LINEAGE','ISSUES'].filter(el=>el!==undefined);
  }

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/files/${match.params.id.toLowerCase()}`
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname,title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...(response.data.response.docs[0]||{}), ...fullResponse, source_data: sourceData }})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }

  const issueFetchList = () => {
    dispatch({
      type: 'set_issue_data',
      issueLoading: true
    })
    axiosCerebrum
      .get(
        `/api/files/${match.params.id.toLowerCase()}/related`,
        {params: {
          relationship:'IMPACTED_BY,IMPACTED_BY_SRC',
          per_page:200,
          sort:'END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type: 'set_issue_data',
            issueData: response.data
          })
          return;
        }
        axiosSolr
          .post('/solr/search/select',{query:'*',limit:response.data.items.length,filter:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`})
          .then(solrRes=>{
            dispatch({
              type: 'set_issue_data',
              issueData: {...response.data,items:solrRes.data.response.docs},
            })
          })
          .catch(error=>{
            dispatch({
              type: 'set_issue_data',
              issueData: state.issueData,
              issueError: true,
            })
          })
      })
      .catch(error=>{
        dispatch({
          type: 'set_issue_data',
          issueData: state.issueData,
          issueError: true,
        })
      })
  }

  useEffect(()=>{
    if(!state.basicData)fetchList();
    if(!state.issueData)issueFetchList()
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.reload_issue){
        issueFetchList()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({
          relations:['SOURCE_OF','SOURCE_FROM'],
          linkableObjects:lineageObjectsMap.FILE
        })
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[])


  useEffect(()=>{
    window.scrollTo(0,0)
    // eslint-disable-next-line
  },[state.tabState])

  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const data = state.basicData;

  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  // buttons.push(
  //   <DropdownButton
  //     iconLabel='dot_menu'
  //     tooltip="More actions"
  //     iconOnly={true}
  //     iconColour={theme.palette.primaryText.light}
  //     title={['OPEN IN APPLICATIONS']}
  //     optionList={[
  //       {onClick:() => history.push(`/lineage_explorer?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'lineage_downstream',text:'Lineage Explorer',group:'OPEN IN APPLICATIONS'},
  //     ]}
  //   />
  // )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )


  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true }),iconLabel:'issue',text:'Add Issue'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
        checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined,
      ]}
    />
  )


  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )



  let bannerdisplayText,bannerCause,bannerVisibility;

  let openIssueCount = state.issueData && state.issueData.items.filter(i=>!i.issue_status_txt || !i.issue_status_txt.includes('CLOSED')).length
  if (state.issueData && openIssueCount > 0) {
    bannerdisplayText = `This file has ${openIssueCount} open or in progress issue(s)`;
    bannerCause = 'issue';
    bannerVisibility = 'visible';
  }

  if(data && data.active_flag === false){
    bannerdisplayText = 'This file has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this file has been deactivated in K. Information on this file is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  let tabOptions = getTabOptions(data);

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note','additional_property','issue']}
        history={history}
      />
      <ProfileLayout
        header={
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='file'
              title={state.basicData.name || state.basicData.signature}
              shouldLoadBreadCrumb
              label='file'
              buttons={buttons}
              data={state.basicData}
              history={history}
              state={state}
              dispatch={dispatch}
              shouldLoadLinkedInstance
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                })
              }}
            />
          </div>}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            label={data.labels}
            tabState={state.tabState}
            fetchList={fetchList}
            data={data}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
        hideSideBar={['LINEAGE','ISSUES'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        disableMinContentHeight={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'files'}
            data={state.basicData}
            mapping={
              ['tags','sourceType','lineage','upstreamSources','downstreamSources','knowledge','trustscore','topUsers','topTeams','lastUpdated','lastUpdatedBy','createdBy']
            }
          />
        }
        />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(FileProfile)));
