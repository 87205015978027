import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import useAlert from '../../../hooks/useAlert';
import { KadaDropdownBtn } from 'kada-component-library'
import { collectionIds } from '../../../utilities';

const StatusSelector = props => {

  const {
    object,
    isEditable,
    validStatus,
    setValidStatus,
    status,
    setStatus
  } = props;

  const noStatusOpt = {id: 0, name: "No Status", icon:'unknown_circle', colour: '#E0E0E0'}

  const {
    sendAlert
  } = useAlert({})

  const loadValidStatus = () => {
    axiosCerebrum
      .get(
        "/api/workflowstatus", {params:{
          object_type: object.object.name,
          "sort.position": "ASC",
          per_page: 20
        }}
      )
      .then(response => {
        setValidStatus([
          noStatusOpt,
          ...response.data.items
        ]);
      })
      .catch(error => {
        setValidStatus([]);
        console.log(error);
      });
  }

  const loadStatus = () => {
    axiosCerebrum
      .get(
        `/api/nodeproperties/${object.id}`
      )
      .then(response => {
        setStatus(response.data.workflow_status || 'No Status');
      })
      .catch(error => {
        setStatus('No Status');
        console.log(error);
      });
  }

  const updateStatus = newStatus => {
    let originalStatus = status;
    setStatus(newStatus);
    axiosCerebrum
      .patch(
        `/api/nodeproperties/${object.id}`, {
          workflow_status: newStatus==='No Status' ? null : newStatus
        }
      )
      .then(response => {
      })
      .catch(error => {
        console.log(error);
        setStatus(originalStatus);
        sendAlert({
          type: 'error',
          message: 'Failed to update status'
        })
      });
  }

  useEffect(()=>{
    if(![
      'DATABASE',
      'SCHEMA',
      'TABLE',
      'COLUMN',
      'CONTENT_APP',
      'CONTENT',
      'CONTENT_CHILD',
      'DATASET',
      'DATASET_TABLE',
      'DATASET_FIELD',
      'DATA_PIPELINE',
      'ML_MODEL',
      'FILE',
      'WORKSPACE',
      'COLLECTION_INSTANCE',
      'TOOL'
    ].includes(object.object.name))return;

    if(object.object.name==='COLLECTION_INSTANCE'){
      if(
        !['GLOSSRY','DATA_GOVERNANCE','DATA_MANAGEMENT'].includes(object.parent.category) &&
        ![
          collectionIds.businessLogic, collectionIds.category, collectionIds.classification, collectionIds.dataProduct, collectionIds.decision,
          collectionIds.domain, collectionIds.howTo, collectionIds.link, collectionIds.list, collectionIds.note, collectionIds.question,
          collectionIds.roadmap, collectionIds.verifiedUseCase
        ].includes(object.parent.id)
      )return;
    }

    if(!validStatus){
      loadValidStatus();
    }
    if(!status){
      setStatus('No Status');
      loadStatus();
    }
    // eslint-disable-next-line
  },[])

  if(!validStatus || validStatus.length===0 ) return <></>
  if(!isEditable && status === 'No Status') return <></>

  return (
    <div style={{ minWidth: 100, width: 'max-content', marginBottom: 16 }}>
      <KadaDropdownBtn
        value={status}
        disabled={!isEditable}
        tooltip={
          isEditable ? 'Click to change status' : 'You do not have permission to change status'
        }
        textOnly
        options={validStatus.map(status => ({
          value: status.name,
          text: status.name,
          color: status.colour,
          icon: status.icon,
          onClick: () => updateStatus(status.name)
        }))}
      />
    </div>
  )
}

StatusSelector.propTypes = {
  history: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  validStatus: PropTypes.array.isRequired,
  setValidStatus: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
}

export default React.memo(StatusSelector);
