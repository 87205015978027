import React, { useState, useEffect, useReducer, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography,LinearProgress } from '@material-ui/core';
import { copyToClipboard } from '../../utilities';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/MacroProfile/Body/Body';
import { handleShareClick, setInitialState, getDispFields, removeUrlQueryArg, getUserRoles } from '../../utilities';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkIsFollowed, checkLineageLinkable, checkTermLinkable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import useAlert from '../../hooks/useAlert';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';



const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
    minWidth:'5.5rem',
    paddingLeft:'8px',
    paddingRight:'8px'
  },
});

const initialState = {
  tagModalOpen:false,
  tabState: 0,
  // basic table data
  basicData: null,
  // insights
  insightsData: null,
  // details
  detailsData: null,
  detailsLoading: null,
  detailsError: null,
 // usage
 usageData: null,
 usageError: null,
 usageLoading: null,
 usagePeriod:3,
 noteTabState:0,
  noteDetailMap:{},
 usageType:'direct',
  //
  // lienage
  lineageData:{
    tabState:0,
    view:'map',
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],
    downstreamObjects:['TABLE','COLUMN','CONTENT','DATASET','DATA_PIPELINE','FILE','QUERY'],

    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
  // lineageView:'recent',
  // lineageHistory:[],
  // lineageNumFound:-1,

 //panel uage data
 usagePanelData: null,
 usagePanelError: null,
 usagePanelLoading: null,


  selectedDate: null,

  linkedData:{},
  linkedTabState:0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }

    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_linked_data':
      return {
        ...state,
        linkedData:action.linkedData,
      }
    case 'set_linked_tab_state':
      return {
        ...state,linkedTabState:action.linkedTabState
      }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_details_data':
      return {
        ...state,
        detailsData: action.detailsData,
        detailsError: action.detailsError,
        detailsLoading: action.detailsLoading
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    // case "set_related_data":
    //   return {
    //     ...state,
    //     relatedData:action.relatedData,
    //     relatedError:action.relatedError,
    //     relatedLoading:action.relatedLoading
    //   }
    case 'set_lineage_data':
      return {
        ...state,lineageData:action.lineageData
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const MacroProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');
  let startState = setInitialState(pageCache,initialState);
  const roles = getUserRoles(sessionData.user_role);

  const getTabOptions = (data) => {
    // return ['DETAILS','KNOWLEDGE', roles.every(el=>el==='90') || data.usage_txt==='UNKNOWN'?undefined:'USAGE','LINEAGE'].filter(el=>el!==undefined);
    return ['DETAILS','KNOWLEDGE', roles.every(el=>el==='90')?undefined:'USAGE','LINEAGE'].filter(el=>el!==undefined);
  }

  const [state, dispatch] = useReducer(reducer, startState);
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  let isLineageLinkable = checkLineageLinkable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])



  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  useEffect(()=>{
    if(tabName && state.basicData){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[state.basicData])

  const fetchList = () => {
    dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataLoading:true })
    axiosCerebrum
      .get( `/api/macros/${match.params.id.toLowerCase()}`)
      .then(async cerebrumResponse=>{
        if(!cerebrumResponse.data){
          dispatch({ type: 'set_basic_data',  basicDataError:true })
          return;
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${cerebrumResponse.data.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(cerebrumResponse.data,'dispTitle'), subTitle:getDispFields(cerebrumResponse.data,'dispSubtitle'),object:cerebrumResponse.data,type:'profile'})
        axiosSolr
          .get(
            `/solr/search/select`,
            {params:{
              q:"*",
              fq:`id:${match.params.id.toLowerCase()}`,
              fl:'*'
            }}
          )
          .then(solrRes=>{
            dispatch({
              type: 'set_basic_data',
              basicData: {
                ...(state.basicData||{}),
                ...(solrRes.data.response.docs[0]||{}),
                ...cerebrumResponse.data,
                source_data:sourceData
              }})
          })
          .catch(error=>{
            console.log(error);
            dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataError:true })
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicData:state.basicData, basicDataError:true })
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }

  const {
    data: detailsData,
    loading: detailsLoading,
    error: detailsError,
    fetchList: detailsFecthlist
  } = useGetCerebrum({
    url: `/api/macros/${match.params.id.toLowerCase()}/related`,
    params:{
      relationship:'REFERENCES',
      object_name:'QUERY',
      per_page:1,
      sort:"START_DESC"
    },
    preventAuto: true
  });

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']})
      }
      if(!isLineageLinkable)return;
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({
          relations:['SOURCE_OF','SOURCE_FROM'],
          linkableObjects:lineageObjectsMap.MACRO
        })
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable])


  // trigger api call when tab state is changed, if data is already fetched, do nothing
  useEffect(() => {
    if(!state.basicData)return;
    switch (getTabOptions(state.basicData)[state.tabState]) {
      case 'DETAILS': //  description
        if (!state.detailsData) detailsFecthlist();
        break;
      default:
        break;
    }
  // eslint-disable-next-line
  }, [state.tabState, state.basicData])

  useEffect(() => {
    if(!state.basicData){
      fetchList();
    }
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  // useEffect(()=>{
  //   dispatch({
  //     type:'set_related_data',
  //     relatedData,relatedError,relatedLoading
  //   })
  // },[relatedLoading,relatedError,relatedData])


  useEffect(() => {
    if(!detailsData || state.detailsData){
      dispatch({
        type: 'set_details_data',
        detailsData: state.detailsData,
        detailsError: detailsError,
        detailsLoading: detailsLoading
      })
    }
  // eslint-disable-next-line
  }, [detailsData, detailsError, detailsLoading])

  useEffect(()=>{
    if(detailsData && detailsData.items.length>0){
      axiosCerebrum
        .get(`/api/queries/${detailsData.items[0].id}`)
        .then(response=>{
          dispatch({
            type: 'set_details_data',
            detailsData: [response.data]
          })
        })
        .catch(error=>{
          dispatch({
            type: 'set_details_data',
            detailsData: state.detailsData,
            detailsError: true,
          })
        })
    }
    // eslint-disable-next-line
  },[detailsData])


  if (state.basicDataLoading) {
    return (
      <div style={{ alignSelf: 'center', width: '18.75rem', marginTop: '20rem',textAlign:'center' }}>
        <Typography className={classes.loadingUsage}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd
        pageHeader={"Oops! Looks like something went not quite right... "}
        pageMessage={`Unable to find ${match.params.label} ${match.params.id.toLowerCase()}`}
      />
    )
  }

  if(!state.basicData)return <div></div>

  const handleCodeClick = () => {
    copyToClipboard(state.detailsData && state.detailsData.code)
    sendAlert({message:'Copied code to clipboard',type:'info'})
  }

  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleCodeClick()}
      iconLabel='copy'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )


  // buttons.push(
  //   <DropdownButton
  //     iconLabel='dot_menu'
  //     tooltip="More actions"
  //     iconOnly={true}
  //     iconColour={theme.palette.primaryText.light}
  //     title={['OPEN IN APPLICATIONS']}
  //     optionList={[
  //       {onClick:() => history.push(`/lineage_explorer?targetObjectId=${match.params.id.toLowerCase()}`),iconLabel:'lineage_downstream',text:'Lineage Explorer',group:'OPEN IN APPLICATIONS'},
  //     ]}
  //   />
  // )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )


  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        checkTermLinkable({sessionData})?{onClick:()=>setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM']}),iconLabel:'glossary',text:'Add Term'}:undefined,
      ]}
    />
  )

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )

  const data = state.basicData;

  let bannerdisplayText,bannerCause,bannerVisibility;

  let isShell = state.basicData.reference===true;

  if (data && isShell){
    let sourceName = data.source_data?data.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Macro is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Macro is referenced in ${data.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      }else{
        bannerdisplayText=(
          <Typography>
            This Macro is referenced in multiple Tools. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Macro is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  if(data && data.active_flag === false ){
    bannerdisplayText = 'This macro has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this macro has been deactivated in K. Information on this macro is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  let tabOptions = getTabOptions(state.basicData);

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note']}
        history={history}
      />
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='table'
              label={'macro'}
              history={history}
              title={data.name ? data.name : 'Untitled'}
              shouldLoadBreadCrumb
              buttons={buttons}
              data={data}
              state={state}
              dispatch={dispatch}
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              shouldLoadLinkedInstance
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                })
              }}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            nodeKey={match.params.id.toLowerCase()}
            label='macro'
            tabState={state.tabState}
            data={data}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            fetchList={fetchList}
            isLineageLinkable={isLineageLinkable}
          />
        }
        hideSideBar={['LINEAGE','ISSUES'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        disableMinContentHeight={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={data}
            fetchList={fetchList}
            cerebrumLabel={'macros'}
            data={state.basicData}
            mapping={['tags','sourceType','domain','verified','verifiedNot','lineage','upstreamSources','downstreamSources','trustscore','knowledge','classification','channels','owner','stewardedBy', 'topUsers', 'topTeams', 'lastRun','runs']}
          />
        }
      />
    </div>)
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(MacroProfile)));
